#w-bottom {
    width: 100vw;
    min-height: calc(100vw - 900px);
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Bebas Neue', sans-serif;
}



#video-wrapper {
    margin-top: 40px;
    width: 1000px;
    height: 562.5px;
    z-index: 1;
}

#video {
    width: 100%;
    height: 100%;
    z-index: 1;
}

#discord-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 50px;
    background-color: #0EFF43;
    color: black;
    font-size: 22px;
    padding: 4px 25px;
    padding-top: 7px;
    cursor: pointer;
    transition: background-color .7s ease-in-out;
    transition: transform .1s ease-in-out;
}

#discord-button:active {
    transform: scale(.97)
}

#discord-button:hover {
    background-color: rgb(2,203,203);
}

#discord-button:active {
    transform: scale(.97)
}

#footer-text {
    color: white;
    margin-top: 19px;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 40px;
}

@media only screen and (max-width: 1100px) {
    #footer-text {
        width: 250px;
    }

    #video-wrapper {
        width: 85vw;
        height: 47.8vw;
    }
}

@media only screen and (max-width: 500px) {
    #footer-text {
        width: 250px;
    }
}
body, html {
    margin: 0;
    padding: 0;
    background-color: #EEEEEE;
    font-family: Roboto, sans-serif;
}

a {
    text-decoration: none;
    color: white;
}
#maincontainer {
    position: relative;
    overflow: hidden;
}

  #fallbackcontainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100vh;
      width: 100vw;
      min-width: 300px;
      min-height: 300px;
      background-image: linear-gradient(to top, rgb(24, 24, 24), rgba(61, 61, 61, 1));
  }

  #fallbacktext {
      color: white;
      font-family: MontSB;
      font-size: 55px;
      text-align: center;
  }

  #fallbackbutton {
      font-size: 20px;
        display: flex;
        justify-content: center;
        color: white;
        align-items: center;
        background-color: black;
        width: 220px;
        height: 55px;
        border-radius: 6px;
        font-family: Varela;
        margin-top: 40px;
        transition: ease-in-out .5s;
  }

  #fallbackbutton:hover {
      box-shadow: 0px 0px 10px 1px #ffffff;
      background-color: #000000;
  }


$max-fighter: 1300px;

.w-container {
    background-color: black;
    width: 100vw;
}

#w-top-wrapper {
    width: 100%;
    height: 66vw;
    min-height: 700px;
    max-height: 900px;
    position: relative;
}

#w-top {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-family: 'Bebas Neue', sans-serif;
    position: relative;
}

#fighters {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    height: calc(100vw * .661);
    background-image: url('../../files/fighters.png');
    background-size: cover;
    max-width: 1300px;
    max-height: calc(1300px * .661);
    bottom: 0px;
}

#w-logo {
    background-image: url('../../files/logo.png');
    background-size: cover;
    width: 104px;
    height: 130px;
    margin-top: 60px;
    margin-bottom: clamp(300px, 32vw, 450px)
}

input {
    color: white;
    font-family: 'Bebas Neue', sans-serif;
    height: 40px;
    width: 406px;
    border: 1px solid #0BCCCC;
    background: transparent;
    box-sizing: border-box;
    font-size: 16px;
    text-align: center;
    letter-spacing: 2px;
    margin-bottom: 10px;
}

input:focus, textarea:focus, select:focus{
    outline: none;
}

::placeholder {
    color: white;
    opacity: .7;
    letter-spacing: 20px;
    margin-left: -17px;
}

.fail-message {
    color: rgb(233, 0, 0);
    font-size: 17px;
    height: 0px;
}

.success-message {
    color: white;
    font-size: 17px;
    height: 0px;
}

#submit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
    margin-bottom: 24px;
    background-color: #0EFF43;
    color: black;
    font-size: 22px;
    padding: 4px 25px;
    padding-top: 7px;
    cursor: pointer;
    transition: background-color .7s ease-in-out;
    transition: transform .1s ease-in-out;
}

#submit-button:active {
    transform: scale(.97)
}

#submit-button:hover {
    background-color: rgb(2,203,203);
}


#top-socials {
    display: flex;
    justify-content: space-between;
    width: 150px;
    color: white;
    position: absolute;
    right: 80px;
    top: 60px;
}

#footer-socials {
    display: flex;
    justify-content: space-between;
    width: 150px;
    color: white;
}

#text {
    text-align: center;
    width: 80%;
    max-width: 450px;
    font-size: 17px;
}

@media only screen and (max-width: 1015px) {
    #fighters {
        bottom: calc(350px - 35vw);
    }
}


@media only screen and (max-width: 915px) {
    #fighters {
        bottom: calc(360px - 35vw);
        width: 105%;
        height: calc(105vw * .661);
    }
}


@media only screen and (max-width: 845px) {
    #fighters {
        bottom: calc(370px - 35vw);
        width: 110%;
        height: calc(110vw * .661);

    }
}
@media only screen and (max-width: 800px) {
    #fighters {
        bottom: calc(380px - 35vw);
        width: 115%;
        height: calc(115vw * .661);

    }

    #top-socials {
        display: none;
    }
}



@media only screen and (max-width: 520px) {
    input {
        width: 80%;
    }
}
